import '../index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Toaster } from '@/components/ui/toaster';
import { useToast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://8b3d0440f645970e987e097dc2d471a8@o4507979775410176.ingest.us.sentry.io/4507979777966080',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/sepame\.net\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.PROD,
});

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const { toast } = useToast();

  React.useEffect(() => {
    const handleGlobalError = (event: ErrorEvent | PromiseRejectionEvent) => {
      console.log('handleGlobalError', event);
      event.preventDefault();
      let errorMessage = 'Algo salió mal. Por favor, intentá de nuevo.';

      if (event instanceof ErrorEvent) {
        errorMessage = event.message || errorMessage;
      } else if (event instanceof PromiseRejectionEvent) {
        errorMessage =
          event.reason?.message || String(event.reason) || errorMessage;
      }

      toast({
        title: 'Ocurrió un error',
        description: errorMessage,
        variant: 'destructive',
      });
    };

    window.addEventListener('error', handleGlobalError);
    window.addEventListener('unhandledrejection', handleGlobalError);

    return () => {
      window.removeEventListener('error', handleGlobalError);
      window.removeEventListener('unhandledrejection', handleGlobalError);
    };
  }, [toast]);

  return <>{children}</>;
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
      <Toaster position="top-right" />
    </ErrorBoundary>
  </React.StrictMode>
);
