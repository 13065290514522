import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';

interface LocationContextType {
  isLocationEnabled: boolean;
  location: GeolocationCoordinates | null;
  handleLocationChange: (checked: boolean) => void;
  isLoading: boolean;
  getLocation(precision: number): GeolocationCoordinates | null;
}

const LocationContext = createContext<LocationContextType | undefined>(
  undefined
);

export function LocationProvider({ children }: { children: React.ReactNode }) {
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const [location, setLocation] = useState<GeolocationCoordinates | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedUseLocation = localStorage.getItem('useLocation');
    if (storedUseLocation === 'true') {
      setIsLocationEnabled(true);
      handleLocationChange(true);
    }
    setIsLoading(false);
  }, []);

  const handleLocationChange = useCallback(
    (checked: boolean) => {
      setIsLocationEnabled(checked);
      setIsLoading(true);
      localStorage.setItem('useLocation', checked.toString());
      if (checked && !location) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation(position.coords);
            setIsLoading(false);
          },
          () => {
            setLocation(null);
            setIsLocationEnabled(false);
            localStorage.setItem('useLocation', 'false');
            setIsLoading(false);
          }
        );
      } else {
        setLocation(null);
        setIsLocationEnabled(false);
        setIsLoading(false);
      }
    },
    [location]
  );

  const getLocation = (precision: number): GeolocationCoordinates | null => {
    if (!location?.latitude || !location.longitude) {
      return null;
    }
    const factor = Math.pow(10, precision);
    const roundedLat = Math.round(location.latitude * factor) / factor;
    const roundedLon = Math.round(location.longitude * factor) / factor;
    return {
      ...location,
      latitude: roundedLat,
      longitude: roundedLon,
    };
  };

  return (
    <LocationContext.Provider
      value={{
        isLocationEnabled,
        location,
        handleLocationChange,
        isLoading,
        getLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

export function useLocation() {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
}
