import { trackEvent } from '@/utils/analytics';
import React from 'react';

function Collaborate() {
  return (
    <div className="container mx-auto p-4 max-w-3xl lg:max-w-4xl xl:max-w-5xl">
      <h1 className="text-3xl font-bold mb-4">Colaborá con SEPAME</h1>
      <p className="mb-4">
        Si te resultó útil esta herramienta para encontrar los productos más
        baratos y cerca de tu casa, podés invitarme un cafecito ☕ para seguir
        mejorando el sitio. ¡Gracias por tu apoyo!
      </p>
      <p>
        <a
          onClick={() => trackEvent('collaborate', {})}
          href="https://cafecito.app/rodrigouroz"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            srcSet="https://cdn.cafecito.app/imgs/buttons/button_6.png 1x, https://cdn.cafecito.app/imgs/buttons/button_6_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_6_3.75x.png 3.75x"
            src="https://cdn.cafecito.app/imgs/buttons/button_6.png"
            alt="Invitame un café en cafecito.app"
          />
        </a>
      </p>
    </div>
  );
}

export default Collaborate;
