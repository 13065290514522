import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Collaborate from './pages/Collaborate';
import { Toaster } from '@/components/ui/toaster';
import './App.css';
import { LocationProvider } from '@/contexts/LocationContext';
import { Loader2 } from 'lucide-react';
import * as Sentry from '@sentry/react';

const ProductDetails = lazy(() => import('./pages/ProductDetails'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <Router>
      <LocationProvider>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow pb-8">
            {' '}
            {/* Add pb-16 for footer space */}
            <SentryRoutes>
              <Route path="/" element={<Home />} />
              <Route path="/acercade" element={<About />} />
              <Route path="/colabora" element={<Collaborate />} />
              <Route
                path="/producto/:productIdentifier"
                element={
                  <Suspense
                    fallback={
                      <div className="flex items-center justify-center h-full">
                        <Loader2 className="w-4 h-4 animate-spin" />
                      </div>
                    }
                  >
                    <ProductDetails />
                  </Suspense>
                }
              />
            </SentryRoutes>
          </main>
          <Footer />
        </div>
        <Toaster />
      </LocationProvider>
    </Router>
  );
}

export default App;
