import { useEffect } from 'react';

export function useImagePreloader(imageUrls: string[], count: number = 5) {
  useEffect(() => {
    const preloadImages = imageUrls.slice(0, count).map((url) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = url;
      return link;
    });

    preloadImages.forEach((link) => document.head.appendChild(link));

    return () => {
      preloadImages.forEach((link) => document.head.removeChild(link));
    };
  }, [imageUrls, count]);
}
