import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { trackEvent } from '@/utils/analytics';
import { Share } from 'lucide-react';
import React from 'react';

const ShareButton: React.FC = () => {
  const { toast } = useToast();

  const handleShare = async () => {
    const shareUrl = window.location.href;

    // track event
    trackEvent('share', { url: shareUrl });

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Mirá lo que encontré en Sepame',
          url: shareUrl,
        });
      } catch (error) {
        // ignore abort error
        if (error instanceof DOMException && error.name === 'AbortError') {
          return;
        }
        console.error('Error al compartir: ', error);
        toast({
          title: 'Error',
          description:
            'No se pudo compartir el enlace. Por favor, intentá de nuevo.',
          variant: 'destructive',
        });
      }
    } else {
      // Fallback to clipboard for desktop
      try {
        await navigator.clipboard.writeText(shareUrl);
        toast({
          title: 'Enlace copiado',
          description: 'El enlace de búsqueda ha sido copiado al portapapeles.',
        });
      } catch (error) {
        console.error('Error al copiar el enlace: ', error);
        toast({
          title: 'Error',
          description:
            'No se pudo copiar el enlace. Por favor, intentá de nuevo.',
          variant: 'destructive',
        });
      }
    }
  };

  return (
    <Button
      variant="default"
      size="sm"
      className="bg-orange-500 text-white hover:bg-orange-600"
      onClick={handleShare}
    >
      <Share className="mr-2 h-4 w-4" />
      Compartir
    </Button>
  );
};

export default ShareButton;
