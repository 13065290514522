import React from 'react';
import { Switch } from '@/components/ui/switch';
import { useLocation } from '@/contexts/LocationContext';

export function LocationSwitch() {
  const { isLocationEnabled, handleLocationChange } = useLocation();

  return (
    <div className="flex items-center space-x-2 relative group">
      <Switch
        id="use-location"
        checked={isLocationEnabled}
        onCheckedChange={handleLocationChange}
      />
      <label
        htmlFor="use-location"
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Cerca de mi
      </label>
    </div>
  );
}
