import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { LocationSwitch } from './LocationSwitch';
import ShareButton from './ShareButton';
import sepameLogo from '@/images/sepame-logo.png';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    navigate(-1);
  };

  const isProductDetailsPage = location.pathname.startsWith('/producto/');
  const isAcercaDePage = location.pathname === '/acercade';
  const isColaboraPage = location.pathname === '/colabora';
  const shouldShowBackButton =
    isProductDetailsPage || isAcercaDePage || isColaboraPage;
  const hasSearched = location.search.includes('q=') || isProductDetailsPage;

  return (
    <header className="bg-white shadow-sm">
      <div className="container mx-auto px-4 py-2 max-w-3xl lg:max-w-4xl xl:max-w-5xl">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="flex items-center gap-4 md:gap-2">
            <Link to="/" className="flex items-center">
              <img src={sepameLogo} alt="SEPAME Logo" className="h-8 mr-2" />
              <h1 className="text-lg md:text-xl">SEPAME</h1>
            </Link>
            <LocationSwitch />
          </div>
          <div className="flex items-center gap-4 md:gap-2 md:mt-0 mt-2">
            {shouldShowBackButton && (
              <button
                onClick={handleGoBack}
                className="text-blue-600 hover:text-blue-800 text-sm"
              >
                Volver
              </button>
            )}
            {hasSearched && <ShareButton />}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
