import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-100 z-10">
      <div className="container mx-auto max-w-3xl lg:max-w-4xl xl:max-w-5xl px-2 py-2 sm:px-4 sm:py-3">
        <div className="flex justify-between items-center text-xs sm:text-sm">
          <div>
            <Link to="/acercade" className="text-blue-500 hover:underline">
              <span className="lg:hidden">Acerca</span>
              <span className="hidden lg:inline">Acerca de</span>
            </Link>
          </div>
          <div>
            <Link to="/colabora" className="text-blue-500 hover:underline">
              Colabora
            </Link>
          </div>
          <div>
            <a
              href="https://3mlziqx63al.typeform.com/to/cBPpvvhd"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Feedback
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
