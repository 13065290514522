import { trackEvent } from '@/utils/analytics';
import { Product } from '@sepa-search/types';
import { ChevronUp } from 'lucide-react'; // Import the ChevronUp icon
import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import Pagination from '../components/Pagination';
import ProductList from '../components/ProductList';
import SearchBar from '../components/SearchBar';
import { useNavigate } from 'react-router-dom';

export type ExtendedProduct = Product & {
  _geoDistance: number;
};

const fetcher = (url: string) =>
  fetch(url).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
  });

function Home() {
  const [query, setQuery] = useState('');
  const [location, setLocation] = useState<{ lat?: number; lng?: number }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasSearched, setHasSearched] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const navigate = useNavigate();

  const updateURL = useCallback(
    (searchQuery: string, lat?: number, lng?: number, page = 1) => {
      const params = new URLSearchParams();
      params.set('q', searchQuery);
      if (lat !== undefined && lng !== undefined) {
        params.set('lat', lat.toString());
        params.set('lng', lng.toString());
      }
      params.set('page', page.toString());
      navigate(`?${params.toString()}`, { replace: true });
    },
    [navigate]
  );

  const buildSearchUrl = (
    searchQuery: string,
    lat?: number,
    lng?: number,
    page = 1
  ) => {
    const params = new URLSearchParams();
    if (searchQuery.match(/^\d+$/)) {
      params.append('product_identifier', searchQuery);
    } else {
      params.append('q', searchQuery);
    }
    if (lat !== undefined && lng !== undefined) {
      params.append('lat', lat.toString());
      params.append('lng', lng.toString());
    }
    params.append('offset', ((page - 1) * 20).toString());
    params.append('limit', '20');
    return `api/search?${params.toString()}`;
  };

  const { data, isValidating } = useSWR(
    query
      ? buildSearchUrl(query, location.lat, location.lng, currentPage)
      : null,
    fetcher
  );
  const handleSearch = (searchQuery: string, lat?: number, lng?: number) => {
    setQuery(searchQuery);
    setLocation({ lat, lng });
    setCurrentPage(1);
    setHasSearched(true);
    updateURL(searchQuery, lat, lng);
    trackEvent('search', { query: searchQuery, page: 1 });
  };

  const handlePageChange = useCallback(
    (newPage: number) => {
      setCurrentPage(newPage);
      updateURL(query, location.lat, location.lng, newPage);
      trackEvent('search', { query, page: newPage });
    },
    [query, location.lat, location.lng, updateURL]
  );

  // Effect to handle shared URLs
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sharedQuery = searchParams.get('q');
    const sharedPage = searchParams.get('page');
    const sharedLat = searchParams.get('lat');
    const sharedLng = searchParams.get('lng');

    if (sharedQuery) {
      setQuery(sharedQuery);
      setLocation({
        lat: sharedLat ? parseFloat(sharedLat) : undefined,
        lng: sharedLng ? parseFloat(sharedLng) : undefined,
      });
      setCurrentPage(sharedPage ? parseInt(sharedPage) : 1);
      setHasSearched(true);
    }
  }, []);

  const searchResults = data?.hits || [];
  const totalPages = data ? Math.ceil(data.estimatedTotalHits / 20) : 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setShowScrollTop(scrollTop > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Effect to handle scrolling to top after page change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  return (
    <div className="container mx-auto p-4 max-w-3xl lg:max-w-4xl xl:max-w-5xl">
      <SearchBar onSearch={handleSearch} />

      {!hasSearched && (
        <div className="hero-section text-center py-12 px-4 bg-gray-100 rounded-lg shadow-md mt-8">
          <h1 className="text-3xl font-bold mb-4">Bienvenido a Sepame</h1>
          <p className="text-xl mb-6">
            Buscá en nuestra base de productos y encontrá lo que necesitás.
          </p>
          <p className="text-lg mb-4">
            Activá tu ubicación para encontrar productos cerca de ti.
          </p>
          <a href="/acercade" className="text-primary hover:underline">
            Más información sobre Sepame
          </a>
        </div>
      )}

      {hasSearched && (
        <ProductList
          products={searchResults}
          isSearching={isValidating}
          hasSearched={hasSearched}
        />
      )}

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-12 right-4 md:bottom-16 md:right-4 bg-primary text-primary-foreground p-2 rounded-full shadow-lg hover:bg-primary/90 transition-colors z-50"
          aria-label="Scroll to top"
        >
          <ChevronUp size={24} />
        </button>
      )}
    </div>
  );
}

export default Home;
