import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useLocation as useRouterLocation } from 'react-router-dom';
import { ScanBarcode, Search } from 'lucide-react';
import React, { useCallback, useState } from 'react';
import { BarcodeScanner } from './BarcodeScanner';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '@/contexts/LocationContext';

interface SearchBarProps {
  onSearch: (query: string, lat?: number, lng?: number) => void;
}

function SearchBar({ onSearch }: SearchBarProps) {
  const location = useRouterLocation();
  const [query, setQuery] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get('q') || '';
  });
  const [showScanner, setShowScanner] = useState(false);
  const {
    isLocationEnabled,
    location: locationContext,
    isLoading,
    getLocation,
  } = useLocation();
  const navigate = useNavigate();

  const handleSearch = useCallback(
    (searchQuery: string) => {
      if (isLocationEnabled && locationContext) {
        const roundedLocation = getLocation(2);
        if (roundedLocation) {
          onSearch(
            searchQuery,
            roundedLocation.latitude,
            roundedLocation.longitude
          );
        }
      } else {
        onSearch(searchQuery);
      }
    },
    [isLocationEnabled, locationContext, onSearch]
  );

  const handleBarcodeScan = useCallback(
    (result: string) => {
      setShowScanner(false);
      setQuery(result);
      navigate(`/producto/${result}`);
    },
    [navigate, setQuery]
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && query && !isLoading) {
        handleSearch(query);
      }
    },
    [query, isLoading, handleSearch]
  );

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-2">
          <Input
            className="w-full p-2 rounded-lg border border-gray-300 focus:ring focus:ring-orange-500"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Buscá por nombre, marca o código de barras"
          />
          <Button
            variant="default"
            className="bg-orange-500 text-white p-3 rounded-lg text-lg hover:bg-orange-600"
            disabled={!query || isLoading || query.trim().length < 4}
            onClick={() => handleSearch(query.trim())}
          >
            <Search />
          </Button>
          <Button
            className="bg-orange-500 text-white p-3 rounded-lg text-lg hover:bg-orange-600"
            variant="default"
            onClick={() => setShowScanner(true)}
          >
            <ScanBarcode />
          </Button>
        </div>
      </div>
      {showScanner && (
        <BarcodeScanner
          onScan={handleBarcodeScan}
          onClose={() => setShowScanner(false)}
        />
      )}
    </div>
  );
}

export default SearchBar;
