export const formatPrice = (price: number) => {
  const p = price.toLocaleString('es-AR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return p;
};

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  // Format date to dd/mm/yyyy
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  // Calculate days ago
  const today = new Date();

  // Get the time difference in milliseconds
  const timeDifference = today.getTime() - date.getTime();

  // Convert the time difference from milliseconds to days
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // 1000ms * 60s * 60m * 24h

  return `${formattedDate} (hace ${daysAgo} día${daysAgo > 1 ? 's' : ''})`;
}
