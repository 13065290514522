import { ExtendedProduct } from '@/pages/Home';
import { Skeleton } from '@/components/ui/skeleton';
import React from 'react';
import ProductCard from './ProductCard';
import { useImagePreloader } from '@/hooks/use-image-preloader';

interface ProductListProps {
  products: ExtendedProduct[];
  isSearching: boolean;
  hasSearched: boolean;
}

function ProductList({ products, isSearching, hasSearched }: ProductListProps) {
  if (isSearching === true && products.length === 0) {
    const skeletonCards = Array(4).fill(0); // Reduced from 6 to 4 skeleton cards
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {skeletonCards.map((_, index) => (
          <div key={index} className="space-y-2">
            <Skeleton className="h-[125px] w-full rounded-xl" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (hasSearched && !isSearching && products.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-xl font-semibold text-gray-600">No hay resultados</p>
      </div>
    );
  }

  const imageUrls = products.map(
    (product) =>
      `https://imagenes.preciosclaros.gob.ar/productos/${product.product_identifier}.jpg`
  );

  useImagePreloader(imageUrls, 6);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      {products.map((product) => (
        <div key={product.uid}>
          <ProductCard product={product} />
        </div>
      ))}
    </div>
  );
}

export default ProductList;
