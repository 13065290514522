import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import useSWR from 'swr';
import Collaborate from '@/pages/Collaborate';
import { formatDate } from '@/utils/format';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

function About() {
  const { data: brandsData } = useSWR('/api/brands', fetcher);
  const { data: healthData } = useSWR('/api/health', fetcher);

  const topBrands = brandsData?.slice(0, 15) || [];
  const lastUpdate = healthData?.lastUpdate
    ? new Date(healthData.lastUpdate).toLocaleDateString()
    : 'N/A';
  const totalProducts =
    healthData?.numberOfDocuments?.toLocaleString('es-AR') || 'N/A';

  return (
    <div className="container mx-auto p-4 max-w-3xl lg:max-w-4xl xl:max-w-5xl">
      <h1 className="text-3xl font-bold mb-4">Acerca de SEPAME</h1>
      <p className="mb-4">
        Este proyecto tiene como objetivo facilitar la búsqueda de productos de
        supermercado más baratos y cercanos a tu ubicación. Toda la información
        presentada en este sitio proviene del dataset público SEPA Precios, que
        podés consultar en{' '}
        <a
          href="https://datos.produccion.gob.ar/dataset/sepa-precios"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 underline"
        >
          datos.produccion.gob.ar/dataset/sepa-precios
        </a>
        . Hemos desarrollado una herramienta que te permite buscar productos de
        manera rápida y sencilla, ya sea por nombre o escaneando el código de
        barras.
      </p>
      <p className="mb-4">
        Es importante destacar que este sitio se limita a indexar y ofrecer un
        sistema de búsqueda de la información provista por SEPA. No somos
        responsables de la calidad, precisión o actualización de los datos. Para
        más detalles sobre la fuente de los precios y productos, te recomendamos
        consultar directamente el dataset de SEPA.
      </p>

      <div className="grid gap-4 mt-8">
        <Card>
          <CardHeader>
            <CardTitle>Estadísticas de la Base de Datos</CardTitle>
          </CardHeader>
          <CardContent className="flex justify-between items-center">
            {healthData && (
              <>
                <div>
                  <p>Última actualización: {formatDate(lastUpdate)}</p>
                  <p>
                    Número total de productos:{' '}
                    {totalProducts.toLocaleString('es-AR')}
                  </p>
                </div>
                <div className="text-right text-sm text-gray-500">
                  Hecho con ❤️ por
                  <br />
                  <a
                    href="mailto:rodrigouroz@gmail.com"
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    rodrigouroz@gmail.com
                  </a>
                </div>
              </>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Top 15 Comercios</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Comercio</TableHead>
                  <TableHead className="text-right">
                    Cantidad de Productos
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {topBrands.map((brand: { value: string; count: number }) => (
                  <TableRow key={brand.value}>
                    <TableCell>{brand.value}</TableCell>
                    <TableCell className="text-right">
                      {brand.count.toLocaleString('es-AR')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>

      <div className="mt-8">
        <Collaborate />
      </div>
    </div>
  );
}

export default About;
