import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ExtendedProduct } from '@/pages/Home';
import { formatPrice } from '@/utils/format';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import genericImage from '@/images/generic.png';

interface ProductCardProps {
  product: ExtendedProduct;
}

function ProductCard({ product }: ProductCardProps) {
  const imageUrl = `https://imagenes.preciosclaros.gob.ar/productos/${product.product_identifier}.jpg`;

  return (
    <RouterLink
      to={`/producto/${product.product_identifier}`}
      className="block h-full"
    >
      <Card className="h-full flex flex-col shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer">
        <CardHeader className="flex-shrink-0 py-3 pb-1 text-center">
          <CardTitle className="font-poppins text-lg font-semibold line-clamp-2 min-h-[3rem] text-gray-700">
            {product.description.toUpperCase()}
          </CardTitle>
        </CardHeader>
        <div className="px-6 pb-4 flex items-center justify-center h-48">
          <img
            src={imageUrl}
            alt={product.description}
            width={200}
            height={200}
            className="max-w-full max-h-full object-contain"
            onError={(event) => {
              const target = event.target as HTMLImageElement;
              target.src = genericImage;
            }}
          />
        </div>
        <CardContent className="flex-grow flex flex-col justify-center items-center">
          <div className="text-center">
            <span className="text-3xl font-bold text-green-600">
              ${formatPrice(product.min_price)}
            </span>
            <div className="text-sm text-gray-500 mt-1">
              ({product.quantity} {product.unit})
            </div>
          </div>
        </CardContent>
      </Card>
    </RouterLink>
  );
}

export default ProductCard;
