import React from 'react';
import { useZxing } from 'react-zxing';
import { Button } from '@/components/ui/button';

interface BarcodeScannerProps {
  onScan: (result: string) => void;
  onClose: () => void;
}

export function BarcodeScanner({ onScan, onClose }: BarcodeScannerProps) {
  const { ref } = useZxing({
    onDecodeResult(result) {
      const scannedText = result.getText().trim();
      if (scannedText) {
        onScan(scannedText);
        onClose(); // Automatically close the scanner after successful scan
      }
    },
    constraints: {
      video: {
        facingMode: 'environment',
        width: { ideal: 1280 },
        height: { ideal: 720 },
      },
    },
    timeBetweenDecodingAttempts: 300,
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg">
        <h2 className="text-lg font-bold mb-4">Escanear código de barras</h2>
        <video ref={ref} className="w-full max-w-sm" />
        <Button onClick={onClose} className="mt-4 w-full">
          Cerrar escáner
        </Button>
      </div>
    </div>
  );
}
